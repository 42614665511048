import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle }) => (
  <div id="header">
    <div id="top_navigation">
      {/* <h1 style={{ margin: 0 }}> */}
        <Link to="/#" >Home</Link>
        <Link to="/#video_covers" >Video Covers</Link>
        <Link to="/#loop_covers" >Loop Covers</Link>
        <Link to="/#live_covers" >Live Covers</Link>
        <Link to="/#social" >Social</Link>

        {/* style={{
            color: `white`,
            textDecoration: `none`,
          }}
           */}
      {/* </h1> */}
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
