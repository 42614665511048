import React from 'react'
import { SocialIcon } from 'react-social-icons';

const Footer = () => (
  <footer id="footer">
    <a class="anchor" id="social" href="/"><span>social</span></a>
    <div className="social_icons" >
        <SocialIcon url="https://twitter.com/angry100aa" />
        <SocialIcon url="https://www.instagram.com/samuelmusicofficial/" />
        <SocialIcon url="https://www.youtube.com/channel/UCQ7GPltGMBWL3JgVoPkSbEw" />
    </div>        
  </footer>
)

export default Footer
